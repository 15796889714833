import React from "react"

const Hero = props => {
  return (
    <section className="hero">
      <div className="hero__main">
        <div className="hero__content">
          <h2 className="hero__title" data-animate>
            {props.title}
          </h2>
        </div>
      </div>
    </section>
  );
}

export default Hero